nav {
  @apply flex justify-between  pr-2 md:pr-5  font-light text-sm sm:text-xl gap-2;
  ul {
    @apply flex items-center text-white gap-3 sm:gap-5;
    li {
      cursor: pointer;
      &:hover {
        @apply text-secondary ease-in-out transition-all duration-300;
      }
    }
  }
}
