.timeline {
  &__item {
    @apply before:opacity-30 sm:before:opacity-100 after:opacity-30 sm:after:opacity-100;
    @apply relative p-2 flex flex-col items-end sm:pr-16 sm:pb-20 justify-center border-none mb-8  w-full before:absolute before:w-1/2 before:border-dashed before:border-4 before:border-secondary before:border-l-0  before:rounded-br-[200px] before:right-0 before:h-[calc(100%_+_36px)] before:rounded-tr-[200px];
    @apply [&:nth-child(even)]:items-start sm:[&:nth-child(even)]:pl-16 [&:nth-child(even)]:pr-0 [&:nth-child(even)]:before:border-r-0 [&:nth-child(even)]:before:rounded-none [&:nth-child(even)]:before:rounded-bl-[200px] [&:nth-child(even)]:before:rounded-tl-[200px] [&:nth-child(even)]:before:border-l-4 [&:nth-child(even)]:before:border-t-4 [&:nth-child(even)]:before:left-0 [&:nth-child(even)]:before:right-auto;
    @apply [&:first-child]:before:border-t-0 [&:first-child]:before:rounded-t-none;
    &__description {
      @apply text-light text-md font-normal mb-3;
      a {
        @apply text-secondary;
      }
    }
    &__year {
      @apply absolute sm:-right-12  before:w-[16px] before:h-[16px] before:rounded-full before:bg-secondary before:absolute sm:before:-left-[20px] before:top-[5px] -top-[15px] sm:top-auto before:opacity-0 sm:before:opacity-100 text-xl sm:text-base;
    }
    &:nth-child(even) {
      .timeline__item__year {
        @apply sm:-left-12 sm:right-auto sm:before:-right-[25px] sm:before:left-auto;
      }
    }
    &--even{
      @apply last:after:-scale-x-100 last:after:translate-x-full;
    }
  }
}
.rocket {
  animation: bounce 1s ease-in-out infinite alternate;
}
@keyframes bounce {
  to {
    transform: translateY(-10px) rotate(180deg);
  }
}
.footer {
  h5 {
    font-size: 0.8em;
    font-weight: normal;
    color: #888;
  }
  .fa-heart {
    color: #f33933;
    position: relative;
    &:after {
      content: "\f004";
      position: absolute;
      left: 0px;
      top: 0px;
      transform: scale(0);
      transform-origin: bottom center;
      opacity: 1;
      animation: heart 2.5s ease infinite;
      font-weight: 600;
    }
    @keyframes heart {
      80% {
        transform: scale(1);
        opacity: 1;
      }
      to {
        transform: scale(1.8);
        opacity: 0;
      }
    }
  }
}
