$base: 1rem;
$space: $base * 1;
$space-xxxs: $base * 0.25;
$space-xxs: $base * 0.35;
$space-xs: $base * 0.5;
$space-sm: $base * 0.75;
$space-md: $base * 1.25;
$space-lg: $base * 1.5;
$space-xl: $base * 2;
$space-xxl: $base * 2.5;
$space-xxxl: $base * 3.5;