.chat-bubble{
    @apply bg-slate-500 rounded-2xl rounded-bl-none absolute bottom-10 left-0 z-30 h-8 w-14 flex items-center justify-center;
}
.typing{
    @apply flex;
}
.typing .dot{
    @apply bg-light rounded-full h-2 w-2 mx-1 transition-all ease-in-out;
    &:nth-child(1){
        animation: bouncing 1s 0.3s ease-in-out infinite alternate;
    }
    &:nth-child(2){
        animation: bouncing 1s 0.6s ease-in-out infinite alternate;
    }
    &:nth-child(3){
        animation: bouncing 1s 0.9s ease-in-out infinite alternate ;
    }
}
@keyframes bouncing {
    from{
        transform: translateY(-3px);
    }
    to{
        transform: translateY(3px);
    }
}