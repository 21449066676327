.chat {
  @apply fixed w-full sm:w-[60%] lg:w-[40%] h-full sm:h-[80%] right-0 sm:right-10 bottom-0 sm:bottom-10 bg-light z-20 rounded-lg;
  clip-path: circle(150% at 100% 100%);
  transform-origin: bottom right;
  transition: clip-path 0.5s ease-in-out;
  &__body::-webkit-scrollbar {
    @apply w-0;
  }
}
.closed {
  // transform: scale(0);
  clip-path: circle(0% at 100% 100%);
  transform-origin: bottom right;
}
