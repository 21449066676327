@import "./variables/spaces.scss";
* {
  @apply text-light;
}
.p {
  &x {
    &-0 {
      padding-left: $space-xxxs;
      padding-right: $space-xxxs;
    }
    &-1 {
      padding-left: $space-xs;
      padding-right: $space-xs;
    }
    &-2 {
      padding-left: $space-sm;
      padding-right: $space-sm;
    }
    &-3 {
      padding-left: $space-md;
      padding-right: $space-md;
    }
    &-4 {
      padding-left: $space-lg;
      padding-right: $space-lg;
    }
    &-5 {
      padding-left: $space-xl;
      padding-right: $space-xl;
    }
    &-6 {
      padding-left: $space-xxl;
      padding-right: $space-xxl;
    }
  }
  &y {
    &-0 {
      padding-top: $space-xxxs;
      padding-bottom: $space-xxxs;
    }
    &-1 {
      padding-top: $space-xs;
      padding-bottom: $space-xs;
    }
    &-2 {
      padding-top: $space-sm;
      padding-bottom: $space-sm;
    }
    &-3 {
      padding-top: $space-md;
      padding-bottom: $space-md;
    }
    &-4 {
      padding-top: $space-lg;
      padding-bottom: $space-lg;
    }
    &-5 {
      padding-top: $space-xl;
      padding-bottom: $space-xl;
    }
    &-6 {
      padding-top: $space-xxl;
      padding-bottom: $space-xxl;
    }
  }
}
.heading {
  @apply text-2xl sm:text-3xl font-semibold;
}
a {
  cursor: pointer;
}
